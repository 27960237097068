<template>
  <nuxt-icon :name="name" :class="classObject" />
</template>

<script setup lang="ts">
export interface Props {
  name: string
  fill?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  fill: false
})

const classObject = reactive({
  icon: true,
  [`icon--${props.name}`]: true,
  'icon--fill': props.fill
})
</script>

<style lang="scss">
@use "~/assets/sass/tools";

.nuxt-icon.icon {
  svg {
    width: auto;
    height: auto;
    margin-bottom: 0;
  }

  &--logo {
    svg {
      width: var(--icon-size--logo);
      height: auto;
    }
  }

  &--fill {
    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
